<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important">
      <v-toolbar
        color="greyBase"
        class="topToolbar"
        dark
        :extended="!$vuetify.breakpoint.mobile"
        flat
        extension-height="45"
      >
        <v-toolbar-title v-if="!$vuetify.breakpoint.mobile">
          <b>Documentation</b>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn-toggle
          mandatory
          outlined
          :light="!$store.state.darkMode"
          v-model="tab"
          rounded
          color="blue"
          class="mr-5"
        >
          <v-btn small outlined>
            <v-icon left small :color="tab == 0 ? 'blue' : 'primaryText'"
              >dashboard</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Workspace</span
            >
          </v-btn>
          <v-btn small outlined>
            <v-icon left small :color="tab == 1 ? 'blue' : 'primaryText'"
              >description</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Files</span
            >
          </v-btn>
          <v-btn small outlined>
            <v-icon left small :color="tab == 2 ? 'blue' : 'primaryText'"
              >insights</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Dashboard</span
            >
          </v-btn>
          <v-btn small outlined>
            <v-icon left small :color="tab == 3 ? 'blue' : 'primaryText'"
              >pending</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Ready to Process</span
            >
          </v-btn>
          <!-- <v-btn small outlined>
            <v-icon left small :color="tab == 4 ? 'blue' : 'primaryText'"
              >dashboard</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Workspace (New)</span
            >
          </v-btn> -->
        </v-btn-toggle>
        <v-btn
          class="mr-1"
          small
          id="info-guide"
          @click="infoStartGuide"
          v-if="!$vuetify.breakpoint.mobile && tab == 1"
          icon
        >
          <v-icon>help</v-icon>
        </v-btn>
        <div v-if="$vuetify.breakpoint.mobile">
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-on="on" v-bind="attrs" color="background">
                <v-icon small left>description</v-icon>
                <span>{{
                  tab == 0
                    ? "Files"
                    : tab == 1
                    ? "Ready to Process"
                    : tab == 2
                    ? "Rejected Document Files"
                    : "Files"
                }}</span>
                <v-chip small color="white" class="ml-2" v-if="tab == 1"
                  ><b style="color: var(--v-background-base)">{{
                    readyFiles.count
                  }}</b></v-chip
                >
                <!-- <v-chip small color="white" class="ml-2" v-if="tab == 2"><b style="color: var(--v-background-base)">{{ rejectedDocumentFiles.count }}</b></v-chip> -->
              </v-chip>
            </template>
            <v-card style="background-color: var(--v-greyBase-base) !important">
              <v-list class="ma-0 pa-0" dense>
                <v-list-item @click="tab = 0">
                  <v-list-item-title>Files</v-list-item-title>
                </v-list-item>
                <v-list-item @click="tab = 1">
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ $t("readyToProcess") }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip color="white" small>
                      <b style="color: var(--v-greyBase-base) !important">{{
                        readyFiles.count
                      }}</b>
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
                <!-- <v-list-item @click="tab = 2">
                  <v-list-item-content>
                    <v-list-item-title 
                    >Rejected Document Files
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip color="white" small>
                      <b style="color: var(--v-greyBase-base) !important;"> {{ rejectedDocumentFiles.count }}</b>
                  </v-chip>
                </v-list-item-action>
                </v-list-item> -->
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </v-toolbar>

      <v-card
        class="mx-auto"
        :max-width="!$vuetify.breakpoint.mobile ? '97vw' : '100%'"
        :style="{
          'margin-top': !$vuetify.breakpoint.mobile ? '-70px' : '0px',
          'border-radius': '0px',
        }"
      >
        <v-card-text
          :style="{
            height: !$vuetify.breakpoint.mobile
              ? 'calc(100vh - 50px)'
              : '86dvh',
            'overflow-y': 'auto',
          }"
          class="px-0 py-0"
        >
          <v-card flat height="inherit">
            <!-- <v-tabs dense v-model="tab" v-if="!$vuetify.breakpoint.mobile">
              <v-tabs-slider colos="primary"></v-tabs-slider>
              <v-tab>Files</v-tab>
              <v-tab
                >Ready to Process
                <v-chip class="ml-2">{{ readyFiles.count }}</v-chip></v-tab
              >
              <v-tab
                >Rejected Document Files
                <v-chip class="ml-2">{{ rejectedDocumentFiles.count }}</v-chip></v-tab
              >
            </v-tabs> -->
            <v-card-text class="my-0 px-0 mt-0" style="height: inherit">
              <!-- <div v-if="tab == 0" style="height: inherit">
                <Workspace />
              </div> -->
              <div v-if="tab == 0" style="height: inherit">
                <v-card flat style="height: inherit">
                  <v-card-text class="pa-0" style="height: inherit">
                    <WorkspaceNew />
                  </v-card-text>
                </v-card>
              </div>

              <div v-else-if="tab == 2" style="height: inherit">
                <v-card flat style="height: inherit">
                  <v-card-text class="pa-0" style="height: inherit">
                    <Dashboard />
                  </v-card-text>
                </v-card>
              </div>
              <div v-else-if="tab == 3" style="height: inherit">
                <v-card flat style="height: 100%">
                  <v-card-text>
                    <ReadyFiles />
                  </v-card-text>
                </v-card>
              </div>
              <div v-else-if="tab == 1">
                <FileTable />
              </div>
              <div v-else-if="tab == 5">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="my-0 py-0"
                    v-if="!$vuetify.breakpoint.mobile"
                  >
                    <v-row justify="end" class="pt-2" no-gutters>
                      <v-col cols="12" sm="12" md="6" lg="4" class="text-right">
                        <v-chip class="mr-5">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                icon
                                v-on="on"
                                id="documentation-download"
                                @click="getShipmentExport()"
                                :loading="loadingExport"
                              >
                                <v-icon small>download</v-icon>
                              </v-btn>
                            </template>
                            Download Exported data
                          </v-tooltip>
                          <v-btn
                            small
                            icon
                            id="documentation-filter"
                            @click="filterDialog = true"
                          >
                            <v-icon small>filter_alt</v-icon>
                          </v-btn>
                          <v-text-field
                            placeholder="Search"
                            class="mb-1"
                            hide-details
                            rounded
                            clearable
                            dense
                            style="width: 300px"
                            v-model="params.search"
                          ></v-text-field>
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="ma-0 py-0 px-2">
                    <v-card-text
                      v-if="!$vuetify.breakpoint.mobile"
                      style="max-width: 100%; overflow-x: auto"
                      class="my-0"
                    >
                      <div
                        :style="{ width: tableWidth }"
                        v-if="shipments.data.length > 0"
                      >
                        <table :width="'100%'">
                          <tr class="mx-0 px-0">
                            <td
                              class="text-center mx-0 px-0"
                              style="
                                cursor: grab;
                                border-right: 1px solid grey;
                                min-height: 20px;
                                min-width: 20px;
                              "
                              v-for="(header, index) in shipmentHeaders"
                              :key="index"
                              :width="header.width ?? '150px'"
                            >
                              <b style="font-size: 12px">{{ header.text }}</b>
                            </td>
                          </tr>
                        </table>
                        <v-divider></v-divider>
                        <div
                          class="mt-1 pt-1 px-1"
                          :style="{ width: '100%' }"
                          v-if="shipments.data.length > 0"
                        >
                          <v-virtual-scroll
                            v-if="shipments.data.length > 0"
                            :bench="10"
                            :items="shipments.data"
                            :height="calculatedHeight"
                            :width="'100%'"
                            item-height="35"
                            class="px-1"
                          >
                            <template v-slot:default="{ item }">
                              <div
                                class="text-center my-0 py-0"
                                :style="{
                                  display: 'flex',
                                  'flex-direction': 'row',
                                  width: '100%',
                                  'background-color': 'transparent',
                                  cursor: 'pointer',
                                }"
                                @click="viewShipment(item)"
                              >
                                <td
                                  v-for="(header, index) in shipmentHeaders"
                                  :key="index"
                                  :style="{
                                    width: header.width ?? '150px',
                                    'font-size': '11px',
                                  }"
                                  class="text-center mt-0 mb-0 pb-0"
                                >
                                  <div
                                    v-if="header.value == 'documentationStatus'"
                                    class="text-center"
                                  >
                                    <v-chip
                                      small
                                      :color="
                                        getMainStatusColor(
                                          item.documentationStatus
                                        )
                                      "
                                      outlined
                                      >{{ item.documentationStatus }}</v-chip
                                    >
                                  </div>
                                  <div
                                    v-else-if="header.value == 'fileNumber'"
                                    class="text-center"
                                  >
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-chip
                                          v-on="on"
                                          small
                                          class="primaryText--text"
                                          outlined
                                          style="border: none"
                                        >
                                          <v-avatar
                                            :color="
                                              item.movementType == 'EXPORT'
                                                ? 'deep-orange'
                                                : 'blue'
                                            "
                                            left
                                          >
                                            <h4 style="color: white">
                                              {{ item.movementType.charAt(0) }}
                                            </h4>
                                          </v-avatar>
                                          {{ item.shipmentFile.fileNumber }}
                                        </v-chip>
                                      </template>
                                      <span
                                        >{{
                                          $Format.capitalizeFirstLetter(
                                            item.movementType
                                          )
                                        }}
                                        Shipment</span
                                      >
                                    </v-tooltip>
                                    <!-- <v-chip
                          v-if="item.shipmentFile"
                          small
                          class="primaryText--text"
                          outlined
                          style="border: none; font-size: 11px"
                          ><span>{{ item.shipmentFile.fileNumber }}</span>
                        </v-chip> -->
                                  </div>
                                  <div
                                    v-else-if="header.value == 'type'"
                                    class="text-center"
                                  >
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          :color="bookingTypeColor(item.type)"
                                          small
                                          icon
                                          v-on="on"
                                        >
                                          <v-icon>{{
                                            bookingTypeIcon(item.type)
                                          }}</v-icon>
                                        </v-btn>
                                      </template>
                                      <span style="font-size: 12px">{{
                                        $Format.capitalizeFirstLetter(item.type)
                                      }}</span>
                                    </v-tooltip>
                                  </div>
                                  <div
                                    v-else-if="
                                      header.value == 'containerQuantity'
                                    "
                                    class="text-center"
                                  >
                                    <v-chip
                                      v-if="!item.breakBulkShipment"
                                      small
                                      class="primaryText--text"
                                      style="font-size: 11px"
                                      ><span>{{ item.containerQuantity }}</span>
                                    </v-chip>
                                    <v-chip
                                      v-else
                                      small
                                      class="primaryText--text"
                                      :color="'teal'"
                                      style="font-size: 11px"
                                      ><span>B</span>
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'palletQuantity'"
                                    class="text-center"
                                  >
                                    <v-chip
                                      small
                                      class="primaryText--text"
                                      outlined
                                      style="border: none; font-size: 11px"
                                      ><span>{{ item.palletQuantity }}</span>
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'unitQuantity'"
                                    class="text-center"
                                  >
                                    <v-chip
                                      small
                                      class="primaryText--text"
                                      outlined
                                      style="border: none; font-size: 11px"
                                      ><span>{{ item.unitQuantity }}</span>
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'vesselVoyage'"
                                    class="text-left"
                                  >
                                    <v-chip
                                      v-if="item.vesselVoyage"
                                      small
                                      class="primaryText--text"
                                      outlined
                                      style="border: none; font-size: 11px"
                                      ><span>{{ item.vesselVoyage }}</span>
                                    </v-chip>
                                  </div>

                                  <div
                                    v-else-if="
                                      header.value == 'portOfLoadValue'
                                    "
                                    class="text-center"
                                  >
                                    <v-tooltip top v-if="item.portOfLoadValue">
                                      <template v-slot:activator="{ on }">
                                        <v-chip
                                          outlined
                                          style="border: none; font-size: 11px"
                                          v-on="on"
                                          small
                                          @click="
                                            addToFilter(
                                              item.portOfLoadValue,
                                              'portOfLoadValue'
                                            )
                                          "
                                        >
                                          <v-avatar size="20" left>
                                            <v-img
                                              contain
                                              :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                                .substring(0, 2)
                                                .toLowerCase()}.svg`"
                                            ></v-img>
                                          </v-avatar>
                                          {{ item.portOfLoadValue }}
                                        </v-chip>
                                      </template>
                                      <span style="font-size: 12px">{{
                                        item.portOfLoadCity
                                      }}</span>
                                    </v-tooltip>
                                  </div>
                                  <div
                                    v-else-if="
                                      header.value == 'finalDestinationValue'
                                    "
                                    class="text-center"
                                  >
                                    <v-tooltip
                                      top
                                      v-if="item.finalDestinationValue"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-chip
                                          outlined
                                          style="border: none; font-size: 11px"
                                          v-on="on"
                                          small
                                          @click="
                                            addToFilter(
                                              item.finalDestinationValue,
                                              'finalDestinationValue'
                                            )
                                          "
                                        >
                                          <v-avatar size="20" left>
                                            <v-img
                                              contain
                                              :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                                                .substring(0, 2)
                                                .toLowerCase()}.svg`"
                                            ></v-img>
                                          </v-avatar>
                                          {{ item.finalDestinationValue }}
                                        </v-chip>
                                      </template>
                                      <span style="font-size: 12px">{{
                                        item.finalDestinationCity
                                      }}</span>
                                    </v-tooltip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'consigneeName'"
                                    class="text-left"
                                  >
                                    <v-tooltip top v-if="item.consignee">
                                      <template v-slot:activator="{ on }">
                                        <v-chip
                                          v-if="item.consignee"
                                          small
                                          class="primaryText--text"
                                          outlined
                                          v-on="on"
                                          style="border: none"
                                        >
                                          <v-avatar
                                            size="24"
                                            :color="
                                              item.consignee &&
                                              item.consignee.logo
                                                ? 'white'
                                                : 'secondary'
                                            "
                                            left
                                          >
                                            <v-img
                                              style=""
                                              v-if="item.consignee.logo"
                                              :src="item.consignee.logo"
                                              contain
                                            ></v-img>
                                            <h3 v-else style="color: white">
                                              {{ item.consigneeName.charAt(0) }}
                                            </h3>
                                          </v-avatar>
                                          {{ item.consigneeName }}
                                        </v-chip>
                                      </template>
                                      <span>{{ item.consignee.name }}</span>
                                    </v-tooltip>
                                  </div>
                                  <div
                                    v-else-if="
                                      header.value == 'shippingLineName'
                                    "
                                    class="text-left"
                                  >
                                    <v-tooltip top v-if="item.shippingLine">
                                      <template v-slot:activator="{ on }">
                                        <v-chip
                                          v-if="item.shippingLine"
                                          small
                                          class="primaryText--text"
                                          outlined
                                          v-on="on"
                                          style="border: none"
                                        >
                                          <v-avatar
                                            size="24"
                                            :color="
                                              item.shippingLine &&
                                              item.shippingLine.logo
                                                ? 'white'
                                                : 'secondary'
                                            "
                                            left
                                          >
                                            <v-img
                                              style=""
                                              v-if="item.shippingLine.logo"
                                              :src="item.shippingLine.logo"
                                              contain
                                            ></v-img>
                                            <h3 v-else style="color: white">
                                              {{
                                                item.shippingLineName.charAt(0)
                                              }}
                                            </h3>
                                          </v-avatar>
                                          {{ item.shippingLineName }}
                                        </v-chip>
                                      </template>
                                      <span>{{ item.shippingLine.name }}</span>
                                    </v-tooltip>
                                  </div>
                                  <div
                                    v-else-if="
                                      header.value == 'onBehalfShipperName'
                                    "
                                    class="text-left"
                                  >
                                    <v-tooltip top v-if="item.onBehalfShipper">
                                      <template v-slot:activator="{ on }">
                                        <v-chip
                                          v-if="item.onBehalfShipper"
                                          small
                                          class="primaryText--text"
                                          outlined
                                          v-on="on"
                                          style="border: none"
                                        >
                                          <v-avatar
                                            size="24"
                                            :color="
                                              item.onBehalfShipper &&
                                              item.onBehalfShipper.logo
                                                ? 'white'
                                                : 'secondary'
                                            "
                                            left
                                          >
                                            <v-img
                                              style=""
                                              v-if="item.onBehalfShipper.logo"
                                              :src="item.onBehalfShipper.logo"
                                              contain
                                            ></v-img>
                                            <h3 v-else style="color: white">
                                              {{
                                                item.onBehalfShipperName.charAt(
                                                  0
                                                )
                                              }}
                                            </h3>
                                          </v-avatar>
                                          {{ item.onBehalfShipperName }}
                                        </v-chip>
                                      </template>
                                      <span>{{
                                        item.onBehalfShipper.name
                                      }}</span>
                                    </v-tooltip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'customerName'"
                                    class="text-left"
                                  >
                                    <v-tooltip top v-if="item.customer">
                                      <template v-slot:activator="{ on }">
                                        <v-chip
                                          v-if="item.customer"
                                          small
                                          class="primaryText--text"
                                          v-on="on"
                                          outlined
                                          @click="
                                            addToFilter(
                                              item.customerName,
                                              'customerName'
                                            )
                                          "
                                          style="border: none"
                                        >
                                          <v-avatar
                                            size="20"
                                            :color="
                                              item.customer &&
                                              item.customer.logo
                                                ? 'white'
                                                : 'secondary'
                                            "
                                            left
                                          >
                                            <v-img
                                              v-if="item.customer.logo"
                                              :src="item.customer.logo"
                                              contain
                                            ></v-img>
                                            <h3 v-else style="color: white">
                                              {{ item.customerName.charAt(0) }}
                                            </h3>
                                          </v-avatar>
                                          {{ item.customerName }}
                                        </v-chip>
                                      </template>
                                      <span>{{ item.customer.name }}</span>
                                    </v-tooltip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'shipperName'"
                                    class="text-left"
                                  >
                                    <v-tooltip top v-if="item.shipper">
                                      <template v-slot:activator="{ on }">
                                        <v-chip
                                          v-if="item.shipper"
                                          small
                                          class="primaryText--text"
                                          v-on="on"
                                          outlined
                                          style="border: none"
                                        >
                                          <v-avatar
                                            size="20"
                                            :color="
                                              item.shipper && item.shipper.logo
                                                ? 'white'
                                                : 'secondary'
                                            "
                                            left
                                          >
                                            <v-img
                                              v-if="item.shipper.logo"
                                              :src="item.shipper.logo"
                                              contain
                                            ></v-img>
                                            <h3 v-else style="color: white">
                                              {{ item.shipperName.charAt(0) }}
                                            </h3>
                                          </v-avatar>
                                          {{ item.shipperName }}
                                        </v-chip>
                                      </template>
                                      <span>{{ item.shipper.name }}</span>
                                    </v-tooltip>
                                  </div>
                                  <div v-else-if="header.value == 'etd'">
                                    <v-chip
                                      v-if="item[header.value]"
                                      small
                                      class="primaryText--text"
                                      outlined
                                      style="border: none"
                                    >
                                      {{ formatDate(item[header.value]) }}
                                    </v-chip>
                                  </div>
                                  <div v-else-if="header.value == 'eta'">
                                    <v-chip
                                      v-if="item[header.value]"
                                      small
                                      class="primaryText--text"
                                      outlined
                                      style="border: none"
                                    >
                                      {{ formatDate(item[header.value]) }}
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'event'"
                                    class="text-left"
                                  >
                                    <v-tooltip
                                      top
                                      v-if="
                                        item.latestEventLocation &&
                                        item.latestEvent
                                      "
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-chip
                                          v-on="on"
                                          small
                                          class="primaryText--text"
                                          outlined
                                          style="border: none"
                                        >
                                          <v-avatar size="24" left>
                                            <v-img
                                              contain
                                              :src="`https://cdn.loglive.io/flags/4x3/${item.latestEventLocation
                                                .substring(0, 2)
                                                .toLowerCase()}.svg`"
                                            ></v-img>
                                          </v-avatar>
                                          {{ item.latestEvent }}
                                        </v-chip>
                                      </template>
                                      {{ formatDateTime(item.latestEventDate) }}
                                    </v-tooltip>
                                  </div>
                                  <div v-else>
                                    <v-chip
                                      v-if="item[header.value]"
                                      small
                                      class="primaryText--text"
                                      outlined
                                      style="border: none"
                                    >
                                      {{ item[header.value] }}
                                    </v-chip>
                                  </div>
                                </td>
                              </div>
                              <v-divider class="my-0 py-0"></v-divider>
                            </template>
                          </v-virtual-scroll>
                        </div>
                      </div>
                      <div
                        class="container"
                        v-if="!loading && shipments.data.length == 0"
                      >
                        <h3 style="color: grey">No Results Found</h3>
                      </div>
                      <div
                        class="container"
                        v-else-if="loading && shipments.data.length == 0"
                      >
                        <valhalla-loading height="500px" />
                      </div>
                    </v-card-text>
                    <v-card-text
                      class="px-2"
                      v-if="$vuetify.breakpoint.mobile"
                      style="height: calc(100dvh - 56px - 96px)"
                    >
                      <v-virtual-scroll
                        v-if="shipments.data.length > 0"
                        :bench="10"
                        :items="shipments.data"
                        :height="
                          !$vuetify.breakpoint.mobile
                            ? calculatedHeight
                            : 'calc(100dvh - 56px - 96px)'
                        "
                        :width="'100%'"
                        item-height="520"
                        class="px-1"
                      >
                        <template v-slot:default="{ item }">
                          <v-card
                            class="mx-0 my-1"
                            rounded
                            outlined
                            tile
                            height="510"
                          >
                            <v-toolbar
                              dense
                              flat
                              class="mobileToolbarCard"
                              style="
                                background-color: var(
                                  --v-greyRaised-base
                                ) !important;
                              "
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-chip
                                    v-on="on"
                                    small
                                    class="primaryText--text"
                                    outlined
                                    style="border: none"
                                  >
                                    <v-avatar
                                      :color="
                                        item.movementType == 'EXPORT'
                                          ? 'deep-orange'
                                          : 'blue'
                                      "
                                      left
                                    >
                                      <h4 style="color: white">
                                        {{ item.movementType.charAt(0) }}
                                      </h4>
                                    </v-avatar>
                                    {{ item.shipmentFile.fileNumber }}
                                  </v-chip>
                                </template>
                                <span
                                  >{{
                                    $Format.capitalizeFirstLetter(
                                      item.movementType
                                    )
                                  }}Shipment</span
                                >
                              </v-tooltip>
                              <v-spacer></v-spacer>
                              <v-chip
                                :color="
                                  getMainStatusColor(item.documentationStatus)
                                "
                                small
                                outlined
                                @click="
                                  addToFilter(
                                    item.documentationStatus,
                                    'documentationStatus'
                                  )
                                "
                              >
                                <span style="font-size: 12px">
                                  {{
                                    $Format.capitalizeFirstLetter(
                                      item.documentationStatus
                                    )
                                  }}
                                </span>
                              </v-chip>
                            </v-toolbar>
                            <v-card-text class="px-2">
                              <v-row>
                                <v-col
                                  v-for="(header, idx) in mobileHeaders"
                                  :key="idx"
                                  class="pb-0"
                                  cols="6"
                                  sm="6"
                                  lg="6"
                                  style="word-wrap: normal"
                                >
                                  <span
                                    style="display: block; font-size: small"
                                  >
                                    <b>{{ header.text }}</b>
                                  </span>
                                  <div v-if="header.value == 'type'" class="">
                                    <v-btn
                                      :color="bookingTypeColor(item.type)"
                                      x-small
                                      left
                                      plain
                                      @click="addToFilter(item.type, 'type')"
                                    >
                                      <v-icon small left>{{
                                        bookingTypeIcon(item.type)
                                      }}</v-icon>
                                      <span style="font-size: 12px">{{
                                        $Format.capitalizeFirstLetter(item.type)
                                      }}</span>
                                    </v-btn>
                                  </div>
                                  <div
                                    v-else-if="
                                      header.value == 'containerQuantity'
                                    "
                                  >
                                    <v-chip
                                      v-if="!item.breakBulkShipment"
                                      small
                                      style="font-size: 11px"
                                      ><span>{{ item.containerQuantity }}</span>
                                    </v-chip>
                                    <v-chip
                                      v-else
                                      small
                                      :color="'teal'"
                                      style="font-size: 11px"
                                      ><span>B</span>
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'palletQuantity'"
                                  >
                                    <v-chip
                                      v-if="item.palletQuantity"
                                      small
                                      style="font-size: 11px"
                                      ><span>{{ item.palletQuantity }}</span>
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'unitQuantity'"
                                  >
                                    <v-chip
                                      v-if="item.unitQuantity"
                                      small
                                      style="font-size: 11px"
                                    >
                                      <span>{{ item.unitQuantity }}</span>
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'vesselVoyage'"
                                  >
                                    <v-chip
                                      v-if="item.vesselVoyage"
                                      small
                                      class="pa-0"
                                      outlined
                                      style="
                                        border: none;
                                        font-size: 11px;
                                        max-width: 100%;
                                        max-height: 5vh;
                                        height: auto;
                                        white-space: pre-line;
                                      "
                                      ><span>{{ item.vesselVoyage }}</span>
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="
                                      header.value == 'portOfLoadValue'
                                    "
                                  >
                                    <v-chip
                                      outlined
                                      style="border: none; font-size: 11px"
                                      small
                                      @click="
                                        addToFilter(
                                          item.portOfLoadValue,
                                          'portOfLoadValue'
                                        )
                                      "
                                    >
                                      <v-avatar size="16" left>
                                        <v-img
                                          contain
                                          :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                            .substring(0, 2)
                                            .toLowerCase()}.svg`"
                                        ></v-img>
                                      </v-avatar>
                                      {{ item.portOfLoadValue }}
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="
                                      header.value == 'finalDestinationValue'
                                    "
                                  >
                                    <v-chip
                                      outlined
                                      style="border: none; font-size: 11px"
                                      small
                                      @click="
                                        addToFilter(
                                          item.finalDestinationValue,
                                          'finalDestinationValue'
                                        )
                                      "
                                    >
                                      <v-avatar size="16" left>
                                        <v-img
                                          contain
                                          :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                                            .substring(0, 2)
                                            .toLowerCase()}.svg`"
                                        ></v-img>
                                      </v-avatar>
                                      {{ item.finalDestinationValue }}
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'consigneeName'"
                                    class="text-left"
                                  >
                                    <v-chip
                                      v-if="item.consignee"
                                      small
                                      class="pl-2 pr-0"
                                      outlined
                                      style="
                                        border: none;
                                        max-width: 100%;
                                        max-height: 5vh;
                                        height: auto;
                                        white-space: pre-line;
                                      "
                                    >
                                      <v-avatar
                                        size="16"
                                        :color="
                                          item.consignee && item.consignee.logo
                                            ? 'white'
                                            : 'secondary'
                                        "
                                        left
                                      >
                                        <v-img
                                          style=""
                                          v-if="item.consignee.logo"
                                          :src="item.consignee.logo"
                                          contain
                                        ></v-img>
                                        <h3 v-else style="color: white">
                                          {{ item.consigneeName.charAt(0) }}
                                        </h3>
                                      </v-avatar>
                                      <span
                                        style="
                                          font-size: 11px;
                                          text-transform: capitalize;
                                        "
                                      >
                                        {{ item.consigneeName }}
                                      </span>
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="
                                      header.value == 'shippingLineName'
                                    "
                                    class="text-left"
                                  >
                                    <v-chip
                                      v-if="item.shippingLine"
                                      small
                                      class="pl-2 pr-0"
                                      outlined
                                      style="
                                        border: none;
                                        max-width: 100%;
                                        max-height: 5vh;
                                        height: auto;
                                        white-space: pre-line;
                                      "
                                    >
                                      <v-avatar
                                        size="16"
                                        :color="
                                          item.shippingLine &&
                                          item.shippingLine.logo
                                            ? 'white'
                                            : 'secondary'
                                        "
                                        left
                                      >
                                        <v-img
                                          style=""
                                          v-if="item.shippingLine.logo"
                                          :src="item.shippingLine.logo"
                                          contain
                                        ></v-img>
                                        <h3 v-else style="color: white">
                                          {{ item.shippingLineName.charAt(0) }}
                                        </h3>
                                      </v-avatar>
                                      <span
                                        style="
                                          font-size: 11px;
                                          text-transform: capitalize;
                                        "
                                      >
                                        {{ item.shippingLineName }}
                                      </span>
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="
                                      header.value == 'onBehalfShipperName'
                                    "
                                  >
                                    <v-chip
                                      v-if="item.onBehalfShipper"
                                      small
                                      class="pl-2 pr-0"
                                      outlined
                                      style="
                                        border: none;
                                        max-width: 100%;
                                        max-height: 5vh;
                                        height: auto;
                                        white-space: pre-line;
                                      "
                                    >
                                      <v-avatar
                                        size="16"
                                        :color="
                                          item.onBehalfShipper &&
                                          item.onBehalfShipper.logo
                                            ? 'white'
                                            : 'secondary'
                                        "
                                        left
                                      >
                                        <v-img
                                          style=""
                                          v-if="item.onBehalfShipper.logo"
                                          :src="item.onBehalfShipper.logo"
                                          contain
                                        ></v-img>
                                        <h3 v-else style="color: white">
                                          {{
                                            item.onBehalfShipperName.charAt(0)
                                          }}
                                        </h3>
                                      </v-avatar>
                                      <span
                                        style="
                                          font-size: 11px;
                                          text-transform: capitalize;
                                        "
                                      >
                                        {{ item.onBehalfShipperName }}
                                      </span>
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'customerName'"
                                    class="text-left"
                                  >
                                    <v-chip
                                      v-if="item.customer"
                                      small
                                      class="pl-2 pr-0"
                                      outlined
                                      @click="
                                        addToFilter(
                                          item.customerName,
                                          'customerName'
                                        )
                                      "
                                      style="
                                        border: none;
                                        max-width: 100%;
                                        max-height: 5vh;
                                        height: auto;
                                        white-space: pre-line;
                                      "
                                    >
                                      <v-avatar
                                        size="16"
                                        :color="
                                          item.customer && item.customer.logo
                                            ? 'white'
                                            : 'secondary'
                                        "
                                        left
                                      >
                                        <v-img
                                          v-if="item.customer.logo"
                                          :src="item.customer.logo"
                                          contain
                                        ></v-img>
                                        <h3 v-else style="color: white">
                                          {{ item.customerName.charAt(0) }}
                                        </h3>
                                      </v-avatar>
                                      <span
                                        style="
                                          font-size: 11px;
                                          text-transform: capitalize;
                                        "
                                        >{{ item.customerName }}</span
                                      >
                                    </v-chip>
                                  </div>
                                  <div
                                    v-else-if="header.value == 'shipperName'"
                                    class="text-left"
                                  >
                                    <v-chip
                                      v-if="item.shipper"
                                      small
                                      outlined
                                      class="pl-2 pr-0"
                                      style="
                                        border: none;
                                        max-width: 100%;
                                        max-height: 5vh;
                                        height: auto;
                                        white-space: pre-line;
                                      "
                                    >
                                      <v-avatar
                                        size="16"
                                        :color="
                                          item.shipper && item.shipper.logo
                                            ? 'white'
                                            : 'secondary'
                                        "
                                        left
                                      >
                                        <v-img
                                          v-if="item.shipper.logo"
                                          :src="item.shipper.logo"
                                          contain
                                        ></v-img>
                                        <h3 v-else style="color: white">
                                          {{ item.shipperName.charAt(0) }}
                                        </h3>
                                      </v-avatar>
                                      <span
                                        style="
                                          font-size: 11px;
                                          text-transform: capitalize;
                                        "
                                        >{{
                                          $Format.capitalizeFirstLetter(
                                            item.shipperName
                                          )
                                        }}</span
                                      >
                                    </v-chip>
                                  </div>
                                  <div v-else-if="header.value == 'etd'">
                                    <v-chip
                                      v-if="item[header.value]"
                                      small
                                      outlined
                                      style="border: none"
                                    >
                                      {{ formatDate(item[header.value]) }}
                                    </v-chip>
                                  </div>
                                  <div v-else-if="header.value == 'eta'">
                                    <v-chip
                                      v-if="item[header.value]"
                                      small
                                      class="primaryText--text"
                                      outlined
                                      style="border: none"
                                    >
                                      {{ formatDate(item[header.value]) }}
                                    </v-chip>
                                  </div>

                                  <div
                                    v-else-if="header.value == 'event'"
                                    class="text-left"
                                  >
                                    <v-chip
                                      v-if="
                                        item.latestEventLocation &&
                                        item.latestEvent
                                      "
                                      small
                                      class="pl-2 pr-0"
                                      outlined
                                      style="
                                        border: none;
                                        max-width: 100%;
                                        max-height: 5vh;
                                        height: auto;
                                        white-space: pre-line;
                                      "
                                    >
                                      <v-avatar size="16" left>
                                        <v-img
                                          contain
                                          :src="`https://cdn.loglive.io/flags/4x3/${item.latestEventLocation
                                            .substring(0, 2)
                                            .toLowerCase()}.svg`"
                                        ></v-img>
                                      </v-avatar>
                                      <span
                                        style="
                                          font-size: 11px;
                                          text-transform: capitalize;
                                        "
                                      >
                                        {{ item.latestEvent }}
                                      </span>
                                    </v-chip>
                                  </div>
                                  <div v-else>
                                    <v-chip
                                      v-if="item[header.value]"
                                      small
                                      class="primaryText--text"
                                      outlined
                                      style="border: none"
                                    >
                                      {{ item[header.value] }}
                                    </v-chip>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-btn
                              icon
                              small
                              plain
                              style="
                                position: absolute;
                                right: 5px;
                                bottom: 5px;
                              "
                              @click="viewShipment(item)"
                            >
                              <v-icon>open_in_new</v-icon>
                            </v-btn>
                          </v-card>
                        </template>
                      </v-virtual-scroll>
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="12"
                    class="text-center"
                    v-if="!loading && !$vuetify.breakpoint.mobile"
                  >
                    <el-pagination
                      style="color: var(--v-primaryText-base)"
                      :current-page.sync="page"
                      :pager-count="5"
                      :page-size.sync="params.limit"
                      :page-sizes="[18, 30, 50, 100]"
                      :layout="
                        !$vuetify.breakpoint.mobile
                          ? 'prev, pager, next, jumper, total'
                          : 'prev, pager, next'
                      "
                      :total="shipments.total"
                    >
                    </el-pagination>
                  </v-col>
                </v-row>
              </div>

              <!-- <div v-else-if="tab == 2">
                <v-card flat>
                  <v-card-text class="px-0">
                    <RejectedDocumentFiles />
                  </v-card-text>
                </v-card>
              </div> -->
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog
      v-model="filterDialog"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="filterDialog = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete
            label="Filter by Shipper"
            outlined
            hide-details
            class="my-1"
            hide-no-data
            dense
            v-model="params.filter.shipperId"
            :loading="searchingShippers"
            :search-input.sync="searchShippers"
            :menu-props="{ closeOnContentClick: true }"
            :items="shippers"
            item-text="name"
            item-value="id"
            clearable
          >
          </v-autocomplete>

          <v-autocomplete
            label="Filter by Receiver"
            outlined
            hide-details
            class="my-1"
            hide-no-data
            dense
            v-model="params.filter.consigneeId"
            :loading="searchingReceivers"
            :search-input.sync="searchReceivers"
            :menu-props="{ closeOnContentClick: true }"
            :items="receivers"
            item-text="name"
            item-value="id"
            clearable
          >
          </v-autocomplete>

          <v-autocomplete
            label="Filter by Vessel"
            outlined
            hide-details
            class="my-1"
            hide-no-data
            dense
            v-model="params.filter.vesselName"
            :loading="searchingVessels"
            :search-input.sync="searchVessels"
            :menu-props="{ closeOnContentClick: true }"
            :items="vessels"
            item-text="name"
            item-value="name"
            clearable
          >
          </v-autocomplete>
          <v-checkbox
            label="Breakbulk Only"
            outlined
            hide-no-data
            dense
            v-model="params.filter.breakBulkShipment"
          >
          </v-checkbox>
          <v-list dense>
            <v-divider></v-divider>
            <v-subheader>File Status</v-subheader>
            <v-chip-group v-model="params.filter.documentationStatus" multiple>
              <v-chip outlined value="OPEN" class="mr-2">
                <v-icon
                  v-if="params.filter.documentationStatus.includes('OPEN')"
                  small
                  left
                  color="green"
                >
                  check
                </v-icon>
                Open
              </v-chip>
              <v-chip outlined value="CANCELLED" class="ml-2">
                <v-icon
                  v-if="params.filter.documentationStatus.includes('CANCELLED')"
                  small
                  left
                  color="green"
                  >check
                </v-icon>
                Cancelled
              </v-chip>
              <v-chip outlined value="CLOSED" class="ml-2"
                ><v-icon
                  v-if="params.filter.documentationStatus.includes('CLOSED')"
                  small
                  left
                  color="green"
                  >check</v-icon
                >Closed</v-chip
              >
            </v-chip-group>
            <v-divider></v-divider>
          </v-list>
          <v-row justify="center" class="mt-3">
            <v-btn
              color="danger"
              small
              text
              style="text-transform: none"
              @click="clearFilters()"
            >
              Clear Filters
            </v-btn>
            <v-btn
              color="success"
              small
              text
              style="text-transform: none"
              @click="getShipments(), (filterDialog = false)"
              >Apply Filter</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn
          @click="infoStartGuide"
          icon
          style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0"
          ><v-icon color="primary" size="30">close</v-icon></v-btn
        >
        <v-card-title class="d-flex justify-center">
          Welcome to your Documentation Overview!
        </v-card-title>
        <v-card-text>
          <span>
            <p>
              <b
                >This section provides a comprehensive list of all your
                documentation files.</b
              >
            </p>
            <p>
              Each file corresponds to a specific shipment or grouping of
              cargo/containers, containing relevant documentation and associated
              information.
            </p>
            <p>
              The list displays key header fields that are relevant to each
              file. To locate a specific file, you can use the quick search
              function or apply filter criteria (which we will explain shortly).
              Once you have found the relevant file, clicking on it will lead
              you to a detailed breakdown of the actual documents and
              information.
            </p>
            <p>
              Please note that the list includes all files associated with your
              organization. By default, only OPEN files are displayed. If you
              wish to view CLOSED files, you can update the default filter
              settings.
            </p>
            To begin, click on the START button below. We will then guide you
            through additional tips and explanations.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- BOTTOM FOR FILES -->
    <div class="bottomBar" v-if="$vuetify.breakpoint.mobile && tab == 0">
      <v-chip style="height: 100%">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              class="mr-2"
              icon
              v-on="on"
              id="documentation-download"
              @click="getShipmentExport()"
              :loading="loadingExport"
            >
              <v-icon>download</v-icon>
            </v-btn>
          </template>
          Download Exported data
        </v-tooltip>
        <v-btn
          small
          icon
          id="documentation-filter"
          @click="filterDialog = true"
        >
          <v-icon>filter_alt</v-icon>
        </v-btn>
        <v-text-field
          placeholder="Search"
          class="mb-1"
          hide-details
          rounded
          clearable
          dense
          style="width: 75dvw"
          v-model="params.search"
        ></v-text-field>
      </v-chip>
    </div>
  </div>
</template>

<script>
import dateFormat from "dateformat";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
// import steps from "./Steps/documentationSteps";
import Dashboard from "../components/Shipments/DocumentDashboardTemp.vue";
import FileTable from "../components/Documentation/FileTable.vue";
// import Workspace from "../components/Shipments/DocsWorkspace.vue";
import WorkspaceNew from "../components/Documentation/Workspace.vue";
// import ReadyFiles from "../components/Shipments/ReadyFiles";
import ReadyFiles from "../components/Documentation/ReadyFiles";
// import RejectedDocumentFiles from "../components/Shipments/RejectedDocumentFiles";

export default {
  components: {
    Dashboard,
    FileTable,
    // Workspace,
    WorkspaceNew,
    ReadyFiles,
    // RejectedDocumentFiles,
  },
  data: () => ({
    steps: null,
    allDocuments: [],
    bookingTypes: [
      { value: "FREIGHT", icon: "mode_of_travel", color: "blue" },
      { value: "LOGISTICS", icon: "mode_of_travel", color: "blue" },
      { value: "DOCUMENTATION", icon: "article", color: "secondary" },
      { value: "HAULAGE", icon: "local_shipping", color: "blue-grey" },
      { value: "MONITORING", icon: "thermostat", color: "teal" },
    ],
    customers: [],
    documents: {
      total: 0,
      data: [],
    },
    docParams: {
      limit: 12,
      offset: 0,
      filter: {},
      search: null,
    },
    docPage: 1,
    filterDialog: false,
    loadingExport: false,
    headers: [
      {
        text: "File Number",
        value: "fileNumber",
        align: "center",
      },
      {
        text: "Cancelled",
        value: "cancelled",
        align: "center",
      },
      {
        text: "Linked Bookings",
        value: "bookings",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipper.name",
        align: "center",
      },
      {
        text: "Receiver",
        value: "consignee.name",
        align: "center",
      },
      {
        text: "No. Containers",
        value: "containers",
        align: "center",
      },
      {
        text: "Vessel/Voyage",
        value: "vessel",
        align: "center",
      },
      {
        text: "POL",
        value: "pol",
        align: "center",
      },
      {
        text: "POD",
        value: "pod",
        align: "center",
      },
    ],
    overviewDocumentHeaders: [
      {
        text: "No. of Docs",
        value: "submittedCount",
        align: "center",
      },
      {
        text: "File",
        value: "shipment.shipmentFile.fileNumber",
        align: "left",
      },
      {
        text: "Document",
        value: "shippingDocument.name",
        align: "left",
      },
      {
        text: "Pending Due Date",
        value: "pendingDue",
        align: "center",
      },
      {
        text: "Completed Due Date",
        value: "completedDue",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipment.shipperName",
        align: "left",
      },
      {
        text: "Receiver",
        value: "shipment.receiverName",
        align: "left",
      },
      {
        text: "POL",
        value: "shipment.portOfLoadValue",
        align: "left",
      },
      {
        text: "POD",
        value: "shipment.finalDestinationValue",
        align: "left",
      },
    ],
    shipmentHeaders: [
      {
        text: "File Status",
        value: "documentationStatus",
        sortable: false,
        align: "center",
        width: "100px",
      },
      // {
      //   text: "Vessel Status",
      //   value: "vesselStatus",
      //   align: "center",
      // },
      {
        text: "File No.",
        value: "fileNumber",
        align: "center",
        sortable: false,
      },
      {
        text: "Type",
        value: "type",
        align: "center",
        width: "80px",
        sortable: false,
      },
      {
        text: "Carrier",
        value: "shippingLineName",
        sortable: false,
        align: "center",
      },
      {
        text: "Cargo",
        value: "containerQuantity",
        sortable: false,
        width: "60px",
        align: "center",
      },
      {
        text: "Pallets",
        value: "palletQuantity",
        sortable: false,
        width: "60px",
        align: "center",
      },
      {
        text: "Units",
        value: "unitQuantity",
        sortable: false,
        width: "60px",
        align: "center",
      },

      {
        text: "Shipper",
        value: "shipperName",
        sortable: false,
        align: "left",
      },
      {
        text: "On Behalf Shipper",
        value: "onBehalfShipperName",
        sortable: false,
        align: "left",
      },
      {
        text: "Receiver",
        value: "consigneeName",
        sortable: false,
        align: "left",
      },
      {
        text: "Vessel/Voyage",
        value: "vesselVoyage",
        width: "180px",
        sortable: false,
        align: "center",
      },
      {
        text: "Event",
        value: "event",
        width: "200px",
        align: "center",
        sortable: false,
      },
      {
        text: "POL",
        value: "portOfLoadValue",
        sortable: false,
        align: "center",
      },
      {
        text: "Final Dest.",
        value: "finalDestinationValue",
        sortable: false,
        align: "center",
      },
      {
        text: "ETD/ATD",
        value: "etd",
        sortable: false,
        width: "100px",
        align: "center",
      },
      {
        text: "ETA/ATA",
        value: "eta",
        width: "100px",
        sortable: false,
        align: "center",
      },
      // {
      //   text: "Invoiced",
      //   value: "invoice",
      //   align: "center",
      // }
      // {
      //     text: 'Invoice',
      //     value: 'invoice',
      //     align: 'center',
      // },
    ],
    fileParams: {
      limit: 12,
      offset: 0,
      search: null,
    },
    loading: false,
    loadingFiles: false,
    loadingDocuments: false,
    loadingConsigneeInstruction: false,
    filePage: 1,
    page: 1,
    params: {
      limit: 18,
      offset: 0,
      search: null,
      filter: {
        documentationStatus: ["OPEN"],
      },
    },
    profiles: {
      total: 0,
      data: [],
    },

    shipments: {
      total: 0,
      data: [],
    },
    shipmentDialog: false,
    shipmentFileDialog: false,
    shipment: {},
    shipmentFiles: {
      total: 0,
      data: [],
    },
    shipmentTimeout: null,
    tab: 0,
    updateBadge: 0,
    readyFiles: { count: 0 },
    rejectedDocumentFiles: { count: 0 },
    // Filters
    searchShippers: null,
    searchingShippers: false,
    settingsList: false,
    shippers: [],
    shipperTimer: null,
    shipmentExistingQuery: undefined,

    searchReceivers: null,
    searchingReceivers: false,
    receivers: [],
    receiverTimer: null,

    searchVessels: null,
    searchingVessels: false,
    vessels: [],
    vesselTime: null,

    searchVoyage: null,
    searchingVoyage: false,
    voyages: [],
    voyageTimer: null,
    infoStart: false,
  }),
  watch: {
    // "$route.query.tab": {
    //   immediate: true,
    //   handler(val) {
    //     if (val) this.tab = parseInt(val);
    //   },
    // },
    // "$route.query.type": {
    //   immediate: true,
    //   handler(val) {
    //     if (val) this.docParams.filter.shippingDocumentId = parseInt(val);
    //   },
    // },
    // "$route.query.status": {
    //   immediate: true,
    //   handler(val) {
    //     if (val) this.docParams.filter.status = val;
    //   },
    // },
    "params.search": {
      immediate: true,
      async handler() {
        this.params = {
          ...this.params,
          offset: 0,
        };
        this.getShipments();
      },
    },
    async page(value) {
      this.params.offset = (value - 1) * this.params.limit;
      this.getShipments();
    },
    "params.limit": {
      immediate: true,
      async handler() {
        this.getShipments();
      },
    },
    searchReceivers(val) {
      if (this.receiverTimer) {
        clearTimeout(this.receiverTimer);
      }
      this.searchingReceivers = true;
      this.receiverTimer = setTimeout(async () => {
        this.receivers = await this.$API.getShipmentFilterDetails({
          search: val,
          field: "consigneeId",
        });
        this.searchingReceivers = false;
      }, 500);
    },
    searchShippers(val) {
      if (this.shipperTimer) {
        clearTimeout(this.shipperTimer);
      }
      this.searchingShippers = true;
      this.shipperTimer = setTimeout(async () => {
        this.shippers = await this.$API.getShipmentFilterDetails({
          search: val,
          field: "shipperId",
        });
        console.log(this.shippers);
        this.searchingShippers = false;
      }, 500);
    },
    searchVessels(val) {
      if (this.vesselTimer) {
        clearTimeout(this.vesselTimer);
      }
      this.searchingVessels = true;
      this.vesselTimer = setTimeout(async () => {
        this.vessels = await this.$API.getVesselFilterDetails({
          search: val,
          field: "name",
        });
        this.searchingVessels = false;
      }, 500);
    },
    // searchVoyage(val) {
    //   if (this.voyageTimer) {
    //     clearTimeout(this.voyageTimer);
    //   }
    //   this.searchingVoyage = true;
    //   this.voyageTimer = setTimeout(async () => {
    //     this.voyages = await this.$API.getShipmentFilterDetails({
    //       search: val,
    //       field: "voyage",
    //       filterField: "vesselName",
    //       filterValue: this.params.filter.vesselName,
    //     });
    //     this.searchingVoyage = false;
    //   }, 500);
    // },
  },
  computed: {
    mobileHeaders() {
      return this.shipmentHeaders.filter(
        (x) => x.value != "documentationStatus" && x.value != "fileNumber"
      );
    },
    calculatedHeight() {
      let height = window.innerHeight;
      return height * 0.72 + "px";
    },
    tableWidth() {
      let result = 0;
      this.shipmentHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
  },
  async mounted() {
    this.driver = new Driver({
      animate: false,
    });
  },
  async created() {
    this.getFiles();
    await this.getSteps();
  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'views/Documentation/Steps',
        });
        this.steps = steps.steps
        },
    settings() {
      this.settingsList = true;
    },
    clearFilters() {
      this.params.filter = {
        documentationStatus: ["OPEN"],
      };
      this.filterDialog = false;
      this.getShipments();
    },
    activeFilters() {
      let keys = Object.keys(this.params.filter);
      let count = 0;
      for (let i = 0; i < keys.length; i++) {
        if (this.params.filter[keys[i]]) {
          count++;
        }
      }
      return count;
    },
    bookingTypeColor(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.color : null;
    },
    bookingTypeIcon(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.icon : null;
    },
    calculateContainers(bookings) {
      return bookings
        .map((item) => item.bookingContainers.length)
        .reduce((prev, next) => prev + next);
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
      }
      return result;
    },
    getMainStatusColor(status) {
      switch (status) {
        case "OPEN":
          return "blue";
        case "CANCELLED":
          return "warning";
        case "CLOSED":
          return "red";
      }
    },
    getVesselStatusColor(status) {
      switch (status) {
        case "Awaiting Departure":
          return "orange";
        case "In Transit":
          return "blue";
        case "Arrived":
          return "green";
        case "Cancelled":
          return "red";
        default:
          return "darkgrey";
      }
    },
    getVesselStatusIcon(status) {
      switch (status) {
        case "Awaiting Departure":
          return "location_on";
        case "In Transit":
          return "mode_of_travel";
        case "Arrived":
          return "where_to_vote";
        case "Cancelled":
          return "close";
        default:
          return "info";
      }
    },
    getItemStatusColor(item) {
      let today = dateFormat(new Date(), "yyyy-mm-dd");
      let status = null;
      if (item.submittedCount < item.requiredCount) {
        if (new Date(item.pendingDue) < new Date(today)) {
          status = "Overdue";
        } else if (item.pendingDue == today) {
          status = "Due Today";
        } else {
          status = "Pending";
        }
      } else {
        status = "Complete";
      }
      switch (status) {
        case "Due Today":
          return "orange";
        case "Overdue":
          return "red";
        case "Pending":
          return "blue";
        case "Complete":
          return "green";
        default:
          return "darkgrey";
      }
    },
    async getFiles() {
      this.readyFiles = await this.$API.getReadyFileCount();
      // this.rejectedDocumentFiles = await this.$API.getRejectedDocumentFileCount();
    },
    async getShipmentExport() {
      this.loadingExport = true;
      let shipments = await this.$API.getShipmentExport();
      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        shipments;
      hiddenElement.target = "_blank";
      hiddenElement.download = "Shipments_" + new Date().getTime() + ".xlsx";
      hiddenElement.click();
      this.loadingExport = false;
    },
    async getShipments() {
      if (this.shipmentTimeout) {
        clearTimeout(this.shipmentTimeout);
      }
      if (this.loading && this.shipmentExistingQuery) {
        this.shipmentExistingQuery.abort();
      }
      this.loading = true;
      if (this.params.filter.breakBulkShipment != true) {
        delete this.params.filter.breakBulkShipment;
      }
      this.shipmentTimeout = setTimeout(async () => {
        this.shipmentExistingQuery = new AbortController();
        const signal = this.shipmentExistingQuery.signal;
        this.shipments = await this.$API.getShipments({
          params: this.params,
          signal,
        });
        this.loading = false;
      }, 750);
    },
    getStatusColor(status) {
      switch (status) {
        case "Open":
          return "blue";
        case "Draft":
          return "orange";
        case "Complete":
          return "green";
      }
    },

    async viewShipment(item) {
      // await this.setAccessState()
      // console.log(this.$route)
      this.$router.push({
        path: "/shipment/" + item.shipmentReference,
      });
    },

    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false;
      e.stopPropagation();
      this.driver.defineSteps(this.steps);
      this.driver.start();
    },
  },
};
</script>

<style scoped>
.bottomBar {
  height: 3rem;
  width: 100dvw;
  position: fixed;
  display: flex;
  padding: 0.2rem 0.5rem;
  bottom: 0;
  background-color: var(--v-greyRaised-base) !important;
  align-content: center;
}
.topToolbar :is(.v-toolbar__content) {
  height: 48px !important;
}
.mobileToolbarCard :is(.v-toolbar__content) {
  padding: 5px !important;
}

.inactive {
  rotate: 0deg;
  transition: 0.3s ease-in-out;
}
.active {
  rotate: 90deg;
}
</style>